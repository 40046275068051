import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import axios from 'axios';
import { BASE_URL } from '../config';
import toast, { Toaster } from "react-hot-toast";
import '../App.css';
import { faArrowLeft, faArrowRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';

const ViewProfile = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [formPopup, setFormPopup] = useState(false);
    const [Nft, setNft] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Nft.slice(indexOfFirstItem, indexOfLastItem);

    const navigate = useNavigate();
    const nextPage = () => {
        if (currentPage < Math.ceil(Nft.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const [documentType, setDocumentType] = useState('');
    const [kitNumber, setKitNumber] = useState('');
    const [userId, setUserId] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [reportNum, setReportNum] = useState('');


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const user_id = searchParams.get('id');
        const document = searchParams.get('document_type');
        const imgUrl = searchParams.get('imgUrl');
        setUserId(user_id);
        setImgUrl(imgUrl);
        const formData = {
            userId: user_id,
            documentType: document
        };
        const getRecords = async () => {
            try {    
           const response = await axios.post(`${BASE_URL}/api/get_reports`, formData);
            setNft(response.data.data);
                
            } catch (error) {

                console.log('error',error)
                
            }
        
        }
        getRecords();
    }, []);

    console.log("reports", Nft)
    const [created_report_at, setCreatedReportAt] = useState(null);

    const handleView = async ( id,document_url, document_type, kitnumber,reportNumber,created_at,to_email) => {
        console.log(id,document_url,document_type,kitnumber,reportNumber,created_at,to_email)
        setReportNum(reportNumber)
        setCreatedReportAt(created_at)
        const response = await axios.post(`${BASE_URL}/api/view_access`, { kitnumber: kitnumber, from_email: "ADMIN",reportNumber:reportNumber,created_at:created_at,to_email:to_email});
        const { status } = response.data;
        if (status === '1') {
            window.open(document_url.replace("../client/public/", "/"), '_blank');
        } else {
            setFormPopup(true);
            setDocumentType(document_type);
            setKitNumber(kitnumber);
        }
    };
    console.log("ddddddddddd", document)
    console.log("imageUrl", imgUrl)
    console.log("reportNum", reportNum)
    const [accessRequestId, setAccessRequestId] = useState(null);

    const handleSendRequestAccess = async () => {
        try {
            const getProfile = await axios.post(`${BASE_URL}/api/find_by_id`, { id: userId });
            if (getProfile.data.status === 'success') {
                const email = getProfile.data.data[0].email;
                const response = await axios.post(`${BASE_URL}/api/request-access`, { kitNumber, from_email: "ADMIN", to_email: email, formType: documentType, reportNumber:reportNum,created_report_at:created_report_at});
                if (response.data.status === 'success') {
                    setFormPopup(false);
                    setAccessRequestId(response.data.id); 

                    toast.success('Access request sent successfully');
                } else {
                    console.error('Failed to send access request:', response.data.error);
                    toast.error(response.data.error);
                    setFormPopup(false);
                }
            }
        } catch (error) {
            console.error('Error sending request:', error.message);
        }
    };

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    const handleNavigateBack = () => {
        window.location.href = `/Admin/ViewProfile?id=${userId}`;
        window.location.href = `/Admin/ViewProfile?id=${userId}`;
    };

 
 
    const handleDelete = async (reportId) => {
        console.log('reportId', reportId);
        try {
    const response   =  await axios.delete(`${BASE_URL}/api/admin/delete_profile_report/${reportId}`);
    if(response.data.status === "success"){
        toast.success('Report deleted successfully');

        setTimeout(()=>{
            navigate("/Admin/Profile")
        },3000)
    }
    else{
        toast.error(response.data.message);
    }
          
        } catch (error) {
          console.error('Failed to delete report:', error);
          toast.error('Failed to delete report');
        }
      };
  
    const isReportDeletable = (createdAt) => {
      const reportDate = new Date(createdAt);
      const now = new Date();
      const timeDifference = Math.abs(now - reportDate);
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      return daysDifference <= 2;
    };

    return (
      


<div className={`relative ${isDarkMode ? 'dark' : 'light'}`}>
      <Toaster />
      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
      <div className={`flex min-h-screen font-inter pt-5 ${isDarkMode ? 'bg-[#05295A]' : 'bg-white'}`}>
        <Sidebar isDarkMode={isDarkMode} />
        <div className='lg:w-[70%] w-[95%] mx-auto z-10 relative'>
          <div className='h-screen md:h-full overflow-hidden overflow-y-auto md:overflow-hidden '>
            <button className={`${isDarkMode ? 'text-white' : 'text-black'}`} onClick={handleNavigateBack}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <div className={` ${isDarkMode ? 'bg-white/25' : 'bg-[#3ab7ff28]'} md:w-[90%] mx-auto mt-5 rounded-lg py-5 cursor-pointer`}>
              <div className='w-full'>
                <div className='flex flex-wrap justify-center items-center gap-5'>
                  {currentItems.map((data, index) => (
                    <div key={index} className=" relative bg-white p-3 rounded-lg flex flex-col justify-center items-center lg:w-[23%]"
                      onClick={() => handleView(data.id, data.document_url, data.document_type, data.kitnumber, data.reportNumber, data.created_at, data.email)}>
                      <div className="flex relative justify-center items-center pr-5">
                        <div className="">
                          <p className="text-sm w-5 text-[#000000A6] font-medium rotate-90"></p>
                          <p className="text-sm w-5 text-[#000000A6] font-medium rotate-90"></p>
                        </div>
                        <img src={imgUrl} alt='Nft' className='h-[10rem]' />
                   
                      </div>
                      
                      <div className="w-full overflow-hidden overflow-x-auto mt-2">
                        <p className="text-sm text-[#000000A6] text-center">
                          {truncateString(data.reportNumber, 9)}{"   "}{data.created_at.split('T')[0]}
                        </p>
                        <p className="text-sm text-[#000000A6] text-center">
                          {data.kitnumber}
                        </p>
                        {isReportDeletable(data.created_at) && (
                          <button className="absolute top-2 right-4" onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(data.id);
                          }}>
                            <FontAwesomeIcon icon={faTrash} className="text-red-500" />
                          </button>
                        )}
                      
                        
                        
                      </div>
                      
                    </div>
                  ))}
                </div>
                <div className="mt-4 flex justify-between w-full px-4">
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className="bg-gray-300 text-black py-0.5 px-4 rounded disabled:opacity-50"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} size='sm' />
                  </button>
                  <button
                    onClick={nextPage}
                    disabled={currentPage === Math.ceil(Nft.length / itemsPerPage)}
                    className="bg-gray-300 text-black py-0.5 px-4 rounded disabled:opacity-50"
                  >
                    <FontAwesomeIcon icon={faArrowRight} size='sm' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {formPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
          <div className="relative bg-white rounded-lg p-8 shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Request Access</h2>
            <p className="text-gray-700 mb-6">You need permission to access this form.</p>
            <div className="flex justify-end">
              <button className="bg-blue-500 text-white bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] font-bold rounded-xl py-2 px-5 mr-2" onClick={handleSendRequestAccess}>Send Request Access</button>
              <button onClick={() => setFormPopup(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    )
}

export default ViewProfile;