import React, {useState, useEffect} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios"
import { BASE_URL } from '../config';
const Dashboard = () => {

     // State to keep track of the current mode
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [new_profile_count, setNewProfileCount] = useState(0);
  const [delete_profile_count, setDeleteProfileCount] = useState(0);
  const [ownership_transfer_count, setOwnershipTransferCount] = useState(0);
  const [total_profiles, setTotalProfiles] = useState(0);
  const [active_profiles, setActiveProfiles] = useState(0);
  const [recentMint, setRecentMint] = useState([]);
  const [recent_activity, setRecentActivity] = useState([]);

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    const fetchUserProfiles = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/get_dashboard_stats`);
            if (response.data.status === 'success') {
                setNewProfileCount(response.data.new_profile);
                setDeleteProfileCount(response.data.deleted_profile);
                setOwnershipTransferCount(response.data.new_profile);
                setTotalProfiles(response.data.total_profile);
                setActiveProfiles(response.data.active_profile);
                setRecentMint(response.data.recent_mint)
                setRecentActivity(response.data.recent_activity);
            } else {
                console.error('Failed to fetch user profiles:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching user profiles:', error.response.data.error);
        }
    };

    fetchUserProfiles();
}, []);

function formatDateToDaysAgo(dateString) {
    const currentDate = new Date();
    const pastDate = new Date(dateString);

    const timeDifference = currentDate.getTime() - pastDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
}
  return (
  <div className={`relative ${isDarkMode ? 'dark' : 'light'}`}>
     <div className="">
                <img src="../assets/Ellipse 271.png" className=" absolute bottom-0 right-0 " />
            </div>
    <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
  
    
    <div className={` flex min-h-screen font-inter pt-5 2xl:pt-20 ${isDarkMode ? 'bg-[#05295A]' : 'bg-white'} `}>
   <Sidebar isDarkMode={isDarkMode} />
        <div className='lg:w-[80%] relative  w-full xl:mx-10 md:mx-5 sm:mx-10 mx-5 flex md:flex-row flex-col gap-10 '>
            <div className='lg:w-[60%] md:w-[55%] flex flex-col gap-7'>
                <div className={` rounded-lg lg:px-8 px-5 py-4 pb-5 ${isDarkMode ? 'bg-white' : 'bg-[#EDEDED]' } `}>
                    <div className='flex sm:items-start items-center'>
                    <div className='flex flex-col gap-2'>
                        <p className='font-bold 2xl:text-5xl  lg:text-3xl md:text-2xl sm:text-3xl text-xl'>Create and Transfer ownership to the users</p>
                        <p className='text-xs 2xl:text-lg text-[#404040]'>The world's first Lab Reports as NFT Platform</p>
                    </div>
                    <div>
                        <img src="../assets/As2.png" className='2xl:w-72' />
                    </div>
                    </div>

                    <div className='flex gap-5 lg:mt-5 mt-3 sm:mx-5 justify-start'>
                        <a href="/Admin/Createprofile" ><button className='bg-black 2xl:text-base sm:text-sm text-xs text-white font-semibold sm:px-5 px-4 py-2 rounded-full'>Create Profile</button></a>
                        {/* <button className='border border-black 2xl:text-base sm:text-sm text-xs font-semibold sm:px-5 px-4 py-2 rounded-full '>Connect Wallet</button> */}
                    </div>
                </div>

{/* Minted NFT */}
                
                <div className=''>
    <div className='flex justify-between'>
        <p className='text-lg font-bold text-[#3AB6FF]'>User Profiles</p>
        <div className='flex items-center gap-3 text-white'>
            <a href='/Admin/Profile'><p>View All</p></a>
            <FontAwesomeIcon icon={faArrowRight} />
        </div>
    </div>

    {recentMint.length === 0 ? (
        <p className={`text-[#D8D8D8] ${isDarkMode ? 'text-white' : 'text-black'} text-sm mt-5 font-bold`}>No user profiles found.</p>
    ) : (
        <div className='flex sm:flex-row flex-col sm:gap-10 gap-5 mt-5'>
            {recentMint.map((data, index) => (
                <div key={index} className={` sm:w-[27%] w-[50%] md:mx-0 mx-auto ${isDarkMode ? 'bg-white' : 'bg-[#EDEDED]' } p-3 rounded-md flex flex-col items-center justify-center`}>
                    <img src='../assets/Group 1000015035.png' className='' />
                    <div className="w-full overflow-hidden overflow-x-auto mt-2 ">
                            <p className="text-sm text-[#000000A6] text-center">
                              {data.email}
                            </p>
                          </div>
                </div>
            ))}
        </div>
    )}
</div>

                <div className='flex sm:flex-row flex-col z-30 lg:gap-10 gap-5' >
                    <div className='sm:w-[55%] w-full'>
                        <p className={`text-base font-semibold ${isDarkMode ? 'text-white' : 'text-[#3AB6FF]' } `}>Total Profiles</p>
                        
                            <div className={`w-full  md:py-7 py-3 pr-3 rounded-md flex md:flex-row flex-col md:gap-0 gap-2 justify-center items-center mt-3 ${isDarkMode ? 'bg-[#FFFFFF1C]' : 'bg-[#3ab7ff28]' }`} >
                            <div className='flex  -space-x-2 px-2'>
                    
                        <img src="../assets/Ellipse 283.png" className='2xl:h-10 inline-block transition duration-300 hover:translate-y-2 ' />
                    
                    
                        <img src="../assets/Ellipse 280.png" className='2xl:h-10 inline-block transition duration-300 hover:translate-y-2 ' />
                    
                    
                        <img src="../assets/Ellipse 281.png" className='2xl:h-10 inline-block transition duration-300 hover:translate-y-2 ' />
                   
                   
                        <img src="../assets/Ellipse 282.png" className='2xl:h-10 inline-block transition duration-300 hover:translate-y-2 ' />
                   
                    </div>
                    <div className={` md:border-l-2 md:border-t-0 border-t-2 ${isDarkMode ? 'text-white border-white/50' : 'text-black border-black/70'}  pl-2 `}>
                        <p className={`font-bold 2xl:text-4xl text-2xl marker `}>{total_profiles}<span className='text-[#3AB6FF] font-bold'>+</span></p>
                    </div>
                   
                    </div>
                    </div>

                    <div className={`sm:w-[45%] ${isDarkMode ? 'text-white' : 'text-black' }`}>
                        <div className={`text-base  flex items-center gap-3`}>
                            <p>Filter</p>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                        <div className={`  py-5 rounded-md flex flex-col items-center mt-3 ${isDarkMode ? 'bg-[#FFFFFF1C]' : 'bg-[#3ab7ff28]' } `} >
                            <p className='2xl:text-4xl text-2xl font-bold'>{active_profiles}</p>
                            <p className='2xl:text-sm text-xs'>Active Profiles</p>
                        </div>

                    </div>
                </div>

            </div>

            <div className='lg:w-[40%] z-10 md:w-[45%]'>
                <div className='bg-[#3AB6FF] flex justify-center gap-5  rounded-lg px-5 py-4'>
                    <div>
                        <div className='flex items-center gap-3 '>
                            <p className='lg:text-3xl md:text-2xl text-3xl font-bold text-white ' style={{ textShadow: '3px 3px #00000040' }} >My Stats</p>
                            <img src='../assets/Vector (10).png' className='lg:h-5 h-4' />
                        </div>
                        <div className='mt-5 text-white flex flex-col gap-1'>
                        <div  className='flex items-center gap-3'>
                            <p className='font-bold  2xl:text-xl md:text-lg text-xl '>{new_profile_count}</p>
                            <p className='font-normal 2xl:text-xl text-sm'> - Profile created</p>
                        </div>
                        <div className='flex items-center gap-3'>
                            <p className='font-bold  2xl:text-xl md:text-lg text-xl '>{delete_profile_count}</p>
                            <p className='font-normal 2xl:text-xl text-sm'> - Profiles Deleted</p>
                        </div>
                        <div className='flex items-center gap-3'>
                            <p className='font-bold  2xl:text-xl md:text-lg text-xl '>{ownership_transfer_count}</p>
                            <p className='font-normal 2xl:text-xl text-sm'> - Ownership's Transfered</p>
                        </div>
                        </div>
                       
                    </div>
                   
                        <img src="../assets/3 2.png" className='2xl:w-3/12 xl:w-4/12 md:w-3/12 sm:w-2/12 w-3/12 ' />
                   

                </div>

<div>
    <div className={`${isDarkMode ? 'bg-[#FFFFFF1C]' : 'bg-[#00000059]'} rounded-lg px-5 2xl:py-10 py-3 z-50 mt-7`}>
        <div className='flex justify-between items-center'>
            <p className='text-white 2xl:text-xl text-lg font-bold'>Recent Activity</p>
            <p className='text-white 2xl:text-lg text-sm'>View All</p>
        </div>
        {recent_activity.length === 0 ? (
            <p className="text-[#D8D8D8] text-sm mt-5 font-bold">No recent activity found.</p>
        ) : (
            <div className='2xl:mt-10 mt-5 flex flex-col 2xl:gap-5 gap-3'>
                {recent_activity.map((data, index) => (
                    <div key={index} className='flex justify-between items-center'>
                        <div className='flex gap-3 items-center'>
                            <img src='../assets/Ellipse 283(7).svg' className='2xl:h-14 h-10' />
                            <div>
                                <p className='text-[#D8D8D8] 2xl:text-lg text-sm'>{data.kitNumber}</p>
                                <p className='text-[#D8D8D8] 2xl:text-sm text-[11px]'>Admin Transferred ownership</p>
                            </div>
                        </div>
                        <div>
                            <p className='text-[#D8D8D8] 2xl:text-sm text-[11px]'>
                                {(formatDateToDaysAgo(data.created_at) === 0) ? 'Today' : formatDateToDaysAgo(data.created_at) + ' days ago'}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        )}
    </div>
</div>


            </div>
          
           
            
        </div>
    


    </div>
  </div>
  )
}

export default Dashboard