// import React, { useState, useEffect } from "react";
// import "../App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Header from "./Header";
// import Sidebar from "./Sidebar";
// import {
//     faArrowLeft, faArrowRight
// } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";


// const SubadminProfile = () => {

//   const [isDarkMode, setIsDarkMode] = useState(true);
//   const [emailid, setEmailId] = useState("");
//   console.log("emailid",emailid)
//   const [username, setUserName] = useState("");
//   console.log("username", username)

//   const toggleDarkMode = () => {
//     setIsDarkMode(!isDarkMode);
//   };




//   const [adminsData, setAdminsData] = useState([]);
//   useEffect(() => {
//     const fetchUserProfiles = async () => {
//       try {
//         const response = await axios.get('http://localhost:3002/api/admin/get_admins_data');
//         if (response.data.status === 'success') {
//           setAdminsData(response.data.adminsData);
//         } else {
//           console.error('Failed to fetch user profiles:', response.data.error);
//         }
//       } catch (error) {
//         console.error('Error fetching user profiles:', error.response.data.error);
//       }
//     };

//     fetchUserProfiles();
//   }, []);

//   console.log("admin profiles", adminsData)
// //   const entries = adminsData.map((admin, index) => ({
// //     sno: index + 1,
// //     role: admin.role,
// //     email: admin.email,
// //   }));

// const handleDeleteClick = async (email) => {
//     try {
//       const response = await axios.post(
//         "http://localhost:3002/api/admin/block_sub_admin_data",
//         { email }
//       );
//       if (response.data.status === "success") {

//         const refreshedData = adminsData.filter(
//           (admin) => admin.email !== email
//         );
//         setAdminsData(refreshedData);
//       } else {
//         console.error("Failed to delete sub-admin:", response.data.error);
//       }
//     } catch (error) {
//       console.error("Error deleting sub-admin:", error.response.data.error);
//     }
//   };

// const entries = adminsData
//   .filter((admin) => admin.role === 'sub_admin')
//   .map((admin, index) => ({
//     sno: index + 1,
//     role: admin.role,
//     email: admin.email,
//   }));


// const [currentPage, setCurrentPage] = useState(1);
// const [entitiesPerPage, setEntitiesPerPage] = useState(4);

// const indexOfLastEntity = currentPage * entitiesPerPage;
// const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

// let currentEntities = entries.slice(indexOfFirstEntity, indexOfLastEntity);

// const emptyRowCount = entitiesPerPage - currentEntities.length;

// const handlePrevClick = () => {
// setCurrentPage(currentPage - 1);
// };

// const handleNextClick = () => {
// if (currentEntities.length === entitiesPerPage) {
// setCurrentPage(currentPage + 1);
// }
// };

// const handleDropdownChange = (e) => {
// setEntitiesPerPage(parseInt(e.target.value));
// setCurrentPage(1);
// };



//   return (
//     <div className={`relative ${isDarkMode ? 'dark' : 'light'}`}>
//     <div className="">
//             <img src="../assets/Ellipse 271.png" className=" absolute bottom-0 right-0 " />
//         </div>
// <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

// <div className={`flex min-h-screen font-inter pt-5 pb-5 ${isDarkMode ? 'bg-[#05295A]' : 'bg-white' } `}>
//         <Sidebar isDarkMode={isDarkMode} />
//             <div className='lg:w-[80%] w-full relative'>                       
//               <div className='lg:mx-10 mx-5 mt-10'>         
//       <div className={` ${isDarkMode ? 'bg-white/10' : 'bg-[#3ab7ff28]'} w-full rounded-md  overflow-hidden overflow-x-scroll overflow-y-scroll`}>
//       <p
//           className={` ${
//             isDarkMode ? "text-white" : "text-black"
//           } text-end 2xl:text-base text-xs px-3 2xl:py-10 pt-2`}
//         >
//           Show no of entity
//           <select
//             className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
//             onChange={handleDropdownChange}
//             value={entitiesPerPage}
//           >
//             <option className="text-black" value="4">
//               4
//             </option>
//             <option className="text-black" value="5">
//               5
//             </option>
//             <option className="text-black" value="10">
//               10
//             </option>
//           </select>
//         </p>
//         <table className="w-full">
//           <thead className="">
//             <tr className={`border-b border-white/40 2xl:text-2xl ${isDarkMode ? 'text-[#C8C2E8]' : 'text-black'} `}>
//               <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">S.NO</th>
//               <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">Role</th>
//               <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">Email Id</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentEntities.map((data, index) => (
//               <tr key={index} className={`${isDarkMode ? 'text-white/75': 'text-black' }`}>
//                 <td className=" text-sm px-5  text-center 2xl:py-5 py-3">{data.sno}</td>
//                 <td className=" text-sm lg:px-5  text-center 2xl:py-5 py-3 ">{data.role}</td>
//                 <td className=" text-sm lg:px-5  text-center 2xl:py-5 py-3 font-semibold">{data.email}</td>
//                 <td className="text-sm px-5 text-center 2xl:py-5 py-3 font-semibold">
//                 <button
//                   className="text-[#FF3131]"
//                   onClick={() => handleDeleteClick(data.email)}
//                 >
//                   Block
//                 </button>
//               </td>              </tr>
//             ))}
//              {Array.from({ length: emptyRowCount }).map((_, index) => (
//                 <tr key={`empty-${index}`} className="h-20 ">
//                   <td>&nbsp;</td>
//                   <td>&nbsp;</td>
//                   <td>&nbsp;</td>
//                   <td>&nbsp;</td>
//                   <td>&nbsp;</td>
//                 </tr>
//               ))}
//           </tbody>
        


//         </table>

//       </div>
//       <div className=" flex justify-between text-xs px-10 pt-5">
//           <button
//             className="bg-white/30 rounded-md px-5 py-1"
//             onClick={handlePrevClick}
//             disabled={currentPage === 1}
//           >
//             <FontAwesomeIcon icon={faArrowLeft} />
//           </button>
//           <button
//             className="bg-white/30 rounded-md px-5 py-1"
//             onClick={handleNextClick}
//             disabled={currentEntities.length < entitiesPerPage}
//           >
//             <FontAwesomeIcon icon={faArrowRight} />
//           </button>
//         </div>
//       </div>

                        

//             </div> 
            
//         </div>

        
    
//     </div>
//   )
// }

// export default SubadminProfile


import React, { useState, useEffect } from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {
  faArrowLeft,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { BASE_URL } from "../config";

const SubadminProfile = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [adminsData, setAdminsData] = useState([]);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/admin/get_admins_data`
        );
        if (response.data.status === "success") {
          setAdminsData(response.data.adminsData);
        } else {
          console.error("Failed to fetch user profiles:", response.data.error);
        }
      } catch (error) {
        console.error("Error fetching user profiles:", error.response.data.error);
      }
    };

    fetchUserProfiles();
  }, []);
  console.log("adminnnuuuuuuuuuuuuuu", adminsData)

//   const handleDeleteClick = async (email, isBlocked) => {
//     try {
//       const response = await axios.post(
//         "http://localhost:3002/api/admin/block_sub_admin_data",
//         { email, status: isBlocked }
//     );
//       if (response.data.status === "success") {
//         const refreshedData = adminsData.map((admin) =>
//           admin.email === email
//             ? { ...admin, is_profile_blocked: !isBlocked }
//             : admin
//         );
//         setAdminsData(refreshedData);
//       } else {
//         console.error("Failed to toggle sub-admin block status:", response.data.error);
//       }
//     } catch (error) {
//       console.error("Error toggling sub-admin block status:", error.response.data.error);
//     }
//   };

const handleDeleteClick = async (email, isBlocked) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/admin/block_sub_admin_data`,
        { email, status: isBlocked === 'true' ? 'false' : 'true' }
      );
      if (response.data.status === "success") {
        const refreshedData = adminsData.map((admin) =>
          admin.email === email
            ? { ...admin, is_profile_blocked: isBlocked === 'true' ? 'false' : 'true' }
            : admin
        );
        setAdminsData(refreshedData);
      } else {
        console.error("Failed to toggle sub-admin block status:", response.data.error);
      }
    } catch (error) {
      console.error("Error toggling sub-admin block status:", error.response.data.error);
    }
  };
  

  const entries = adminsData
    .filter((admin) => admin.role === "sub_admin")
    .map((admin, index) => ({
      sno: index + 1,
      role: admin.role,
      email: admin.email,
      is_profile_blocked: admin.is_profile_blocked
    }));

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = entries.slice(indexOfFirstEntity, indexOfLastEntity);

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  return (
    <div className={`relative ${isDarkMode ? "dark" : "light"}`}>
      <Header toggleDarkMode={() => setIsDarkMode(!isDarkMode)} isDarkMode={isDarkMode} />
      <div className={`flex min-h-screen font-inter pt-5 pb-5 ${isDarkMode ? "bg-[#05295A]" : "bg-white"}`}>
        <Sidebar isDarkMode={isDarkMode} />
        <div className="lg:w-[80%] w-full relative">
          <div className="lg:mx-10 mx-5 mt-10">
            <div className={` ${isDarkMode ? "bg-white/10" : "bg-[#3ab7ff28]"} w-full rounded-md overflow-hidden overflow-x-scroll overflow-y-scroll`}>
              <p
                className={` ${
                  isDarkMode ? "text-white" : "text-black"
                } text-end 2xl:text-base text-xs px-3 2xl:py-10 pt-2`}
              >
                Show no of entity
                <select
                  className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                  onChange={handleDropdownChange}
                  value={entitiesPerPage}
                >
                  <option className="text-black" value="5">5</option>
                  <option className="text-black" value="10">10</option>
                  <option className="text-black" value="15">15</option>
                </select>
              </p>
              <table className="w-full">
                <thead className="">
                  <tr className={`border-b border-white/40 2xl:text-2xl ${isDarkMode ? "text-[#C8C2E8]" : "text-black"} `}>
                    <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">S.NO</th>
                    <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">Role</th>
                    <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">Email Id</th>
                    <th className="md:px-5 px-20 2xl:text-lg text-base py-5 text-center">Action</th>
                  </tr>
                </thead>
            
                <tbody>
  {currentEntities.length === 0 ? (
    <tr>
      <td colSpan="4" className={` ${isDarkMode ? 'text-white' : 'text-black'} font-bold py-5 text-center`}>
        No sub-admin profiles found
      </td>
    </tr>
  ) : (
    currentEntities.map((data, index) => (
      <tr key={index} className={`${isDarkMode ? "text-white/75" : "text-black"}`}>
        <td className="text-sm px-5 text-center 2xl:py-5 py-3">{data.sno}</td>
        <td className="text-sm lg:px-5 text-center 2xl:py-5 py-3">{data.role}</td>
        <td className="text-sm lg:px-5 text-center 2xl:py-5 py-3 font-semibold">{data.email}</td>
        <td className="text-sm px-5 text-center 2xl:py-5 py-3 font-semibold">
          <button
            className={`text-${data.is_profile_blocked === 'true' ? 'green' : 'red'}-500`}
            onClick={() => handleDeleteClick(data.email, data.is_profile_blocked)}
          >
            {data.is_profile_blocked === 'true' ? "Unblock" : "Block"}
          </button>
        </td>
      </tr>
    ))
  )}

  {Array.from({ length: emptyRowCount }).map((_, index) => (
    <tr key={`empty-${index}`} className="h-14">
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
  ))}
</tbody>
              </table>
            </div>
            <div className="flex justify-between text-xs px-10 pt-5">
              <button
                className={`${
                  isDarkMode ? "bg-white/20" : "bg-[#3ab7ff28]"
                } rounded-md px-5 py-1`}
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className={`${
                  isDarkMode ? "bg-white/20" : "bg-[#3ab7ff28]"
                } rounded-md px-5 py-1`}
                onClick={handleNextClick}
                disabled={currentEntities.length < entitiesPerPage}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubadminProfile;
