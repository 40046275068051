
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import DataSpinners from "../Spinner";
import { BASE_URL } from "./config";
import toast, { Toaster } from 'react-hot-toast';

const DeleteProfile = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationReportModal, setShowConfirmationReportModal] = useState(false);

  const [userToDelete, setUserToDelete] = useState({ userId: null, email: '' });


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/admin/users_report`);
        setUserDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleDeleteUser = async () => {
    try {
      const { userId, email } = userToDelete;
      const response = await axios.delete(`${BASE_URL}/api/admin/delete_users/${userId}/${email}`);
      setUserDetails(userDetails.filter(user => user.user_id !== userId));
  
      if (response.data.status === "success") {
        toast.success(`${userId} id deleted successfully`);
      }
  
      setShowConfirmationModal(false);
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Failed to delete user'); 
    }
  };

  const handleDeleteUserReport = async () => {
    try {
      const { userId, email } = userToDelete;
      const response = await axios.delete(`${BASE_URL}/api/admin/delete_users_report/${userId}/${email}`);
      setUserDetails(userDetails.filter(user => user.user_id !== userId));
  
      if (response.data.status === "success") {
        toast.success(`Deleted users  report  Access successfully`);
      }
  
      setShowConfirmationReportModal(false);
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Failed to delete user'); 
    }
  };

  const openConfirmationModal = (userId, email) => {
    setUserToDelete({ userId, email });
    setShowConfirmationModal(true);
  };



  const openConfirmationReportModal = (userId, email) => {
    setUserToDelete({ userId, email });
    setShowConfirmationReportModal(true)

  };



  
  

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const closeConfirmationReportModal = () => {
    setShowConfirmationReportModal(false);
  };

  if (loading) {
    return <div><DataSpinners/></div>;
  }

  return (
    <div className={`relative ${isDarkMode ? 'dark' : 'light'}`}>
      <Toaster />
      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
      <div className="">
        <img src="../assets/Ellipse 271.png" className="absolute bottom-0 right-0 " alt="Background" />
      </div>
      <div className={` ${isDarkMode ? 'bg-[#05295A]' : 'bg-white'} flex min-h-screen font-inter pt-5`}>
        <Sidebar isDarkMode={isDarkMode} />
        <div className="lg:w-[80%] w-full mx-5 custom-scrollbar">
          <div className={` ${isDarkMode ? 'bg-white/25' : 'bg-[#3ab7ff28]'} py-4 px-5 rounded-lg `}>
            <div className="">
              <div className="overflow-y-auto 2xl:h-[40rem] xl:h-[28rem] lg:h-[26rem] h-[28rem]">
                <div className="flex flex-col gap-3">
                  {userDetails.map((user, index) => (
                    <div key={index} className="bg-white z-10 rounded-lg flex flex-wrap md:justify-between justify-center items-center xl:gap-5 md:gap-2 gap-5 xl:p-3 p-2">
                      <div className="lg:w-[30%] flex items-center lg:gap-5 gap-2 2xl:text-lg xl:text-base text-sm">
                        <img src="../assets/Group 1000015035.png" className="w-4/12" alt="User Avatar" />
                        <div>
                          {/* <p className="text-[#3AB6FF] ">{user.user_id}</p> */}
                          <p className="text-[#3AB6FF] font-semibold ">{user.email}</p>
                        </div>
                      </div>
                      <div className="lg:w-[30%] flex gap-2">
                        <div>
                          <p className="text-[#7E7E7E] 2xl:text-xl xl:text-base text-sm font-semibold">Report Status:</p>
                        </div>
                        <div className="flex flex-col gap-2">
                          {user.reports.map((report, idx) => (
                            <div key={idx} className="flex 2xl:gap-5">
                              <p className="w-[90%] text-[#363636] font-medium 2xl:text-lg text-sm flex gap-10 items-center">{report.document_type}</p>
                              <p className="w-[10%]">
                                <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#1EEF32' }} />
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="lg:w-[25%] xl:mx-5 2xl:text-lg xl:text-base text-sm flex flex-col justify-center items-center">
                        {/* <div className="">
                          <p className="text-[#7E7E7E] flex gap-1">Ownership :</p>
                          <p className="text-[#363636] font-semibold flex gap-2">Transfered <span><FontAwesomeIcon icon={faCircleCheck} style={{ color: '#1EEF32' }} /></span></p>
                        </div> */}

                      <button onClick={() => openConfirmationModal(user.user_id, user.email)} className="flex gap-2 px-7 items-center justify-center border rounded-full border-black p-1 mx-7 mt-5">
                          <img src="../assets/Vector (11).png" alt="Delete Icon" />
                          <p className="font-bold 2xl:text-lg text-sm">Delete User</p>
                        </button>
                        <button onClick={() => openConfirmationReportModal(user.user_id, user.email)} className="flex gap-2 px-7 items-center justify-center border rounded-full border-black p-1 mx-7 mt-5">
                          <img src="../assets/Vector (11).png" alt="Delete Icon" />
                          <p className="font-bold 2xl:text-lg text-sm">Delete  Report Access</p>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <p className="text-lg font-semibold">Are you sure you want to delete {userToDelete.email} profile?</p>
            <div className="flex justify-end gap-3 mt-5">
              <button onClick={handleDeleteUser} className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md">Delete</button>
              <button onClick={closeConfirmationModal} className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-md">Cancel</button>
            </div>
          </div>
        </div>
      )}


{showConfirmationReportModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <p className="text-lg font-semibold">Are you sure you want to delete {userToDelete.email} profile  Access?</p>
            <div className="flex justify-end gap-3 mt-5">
              <button onClick={handleDeleteUserReport} className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md">Delete</button>
              <button onClick={closeConfirmationReportModal} className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-md">Cancel</button>
            </div>
          </div>
        </div>
      )}






    </div>
  );
};

export default DeleteProfile;