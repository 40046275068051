
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setAdminEmail } from "../store";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../config";

const Sidebar = ({ isDarkMode }) => {
  const count =  useSelector((state) => state.adminNotificationCount);

  const SideMenu = [
    {
      img: "../assets/Vector (5).png",
      name: "Profiles",
      link: "/Admin/Profile",
    },
    {
      img: "../assets/Vector (5).png",
      name: "Doctor Profiles",
      link: "/Admin/DoctorProfile",
    },
    {
      img: "../assets/Vector (6).png",
      name: "Wallet",
      link: "/Admin/Wallet",
    },
    {
      img: "../assets/Vector (8).png",
      name: "Create Profile",
      link: "/Admin/CreateProfile",
    },
    {
      img: "../assets/Vector (9).png",
      name: "Notifications",
      link: "/Admin/Notifications",
    },
    {
      img: "../assets/upload.png",
      name: "Upload Document",
      link: "/Admin/Uploaddocument",
    },
    {
      img: "../assets/delete.png",
      name: "Delete Profile",
      link: "/Admin/DeleteProfile",
    },
    {
      img: "../assets/pass.png",
      name: "Change Password",
      link: "/Admin/ChangePassword",
    },
  ];

  const [isAdmin, setIsAdmin] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [selectedLink, setSelectedLink] = useState(null);
  const adminEmail = useSelector((state) => state.admin_email);
  const [adminsData, setAdminsData] = useState([]);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/admin/get_admins_data`
        );
        if (response.data.status === "success") {
          setAdminsData(response.data.adminsData);
        } else {
          console.error("Failed to fetch user profiles:", response.data.error);
        }
      } catch (error) {
        console.error(
          "Error fetching user profiles:",
          error.response.data.error
        );
      }
    };

    fetchUserProfiles();
  }, []);

  useEffect(() => {
    const isAdmin = adminsData.some(
      (admin) => admin.email === adminEmail && admin.role === "admin"
    );
    setIsAdmin(isAdmin);
  }, [adminEmail, adminsData]);

  if (isAdmin) {
    SideMenu.push(
      {
        img: "../assets/Vector (8).png",
        name: "Sub Admin",
        link: "/Admin/SubAdmin",
      },
      {
        img: "../assets/Vector (5).png",
        name: "Sub Admin Profiles",
        link: "/Admin/SubadminProfile",
      }
    );
  }

  const dispatch = useDispatch();
  const Logout = () => {
    dispatch(setAdminEmail(""));
    toast.success("Logged Successfully...Redirecting to Login Page");
    setTimeout(() => {
      window.location.href = "/admin/Login";
    }, 500);
  };

  const location = useLocation();

  const toggleHeader = () => {
    setShowHeader(!showHeader);
  };

  useEffect(() => {
    const pathname = location.pathname;
    const index = SideMenu.findIndex((item) => item.link === pathname);
    setSelectedLink(index);
  }, [location.pathname, SideMenu]);

  const handleLinkClick = (index) => {
    setSelectedLink(index);
  };

  return (
    <div className={`lg:w-[20%] ${isDarkMode ? "dark" : "light"}`}>
      <Toaster />
      <div
        className={`relative font-inter ${
          isDarkMode ? "bg-[#05295A] text-white" : "bg-white text-black"
        } min-h-screen`}
      >
        <div className="">
          {/* Side Bar */}
          <div
            className={`hidden h-full lg:flex flex-col justify-between items-center ease-in-out duration-500 min-h-screen p-3`}
          >
            <div>
              <div className="flex justify-center items-center bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] font-bold rounded-xl py-2 px-5">
                <a href="/Admin/Dashboard">
                  <p className="font-bold text-sm text-center text-white">
                    ADMIN DASHBOARD
                  </p>
                </a>
              </div>

              <div className="flex justify-center mt-10">
                <ul className="flex flex-col gap-5 z-10 text-sm">
                  {SideMenu.map((data, index) => (
                    <li key={index}>
                      <a href={data.link}>
                        <button
                          onClick={() => handleLinkClick(index)}
                          className={`w-full flex items-center gap-5 rounded-xl py-2 px-5 ${
                            selectedLink === index
                              ? "bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] font-bold text-white"
                              : ""
                          }`}
                        >
                          <img src={data.img} className="size-4" />
                          <p>{data.name}</p>
                          {data.name === "Notifications" && count > 0 && (
                            <span className="ml-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                              {count}
                            </span>
                          )}
                        </button>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              onClick={() => Logout()}
              className="flex cursor-pointer justify-center items-center z-30 gap-5 my-10 transition duration-300 ease-in-out transform hover:scale-105"
            >
              <img src="../assets/logout-1 1.png" className="w-5" />
              <p className="text-sm font-semibold">Log Out</p>
            </div>
          </div>
          {/* Near Logout */}
          <div className="">
            <img
              src="../assets/Ellipse 277.png"
              className="absolute bottom-0 left-0"
            />
          </div>

          {/* Menu bar */}
          <div
            onClick={toggleHeader}
            className="absolute top-3 sm:right-0 md:-right-5 right-5 block md:hidden"
          >
            {showHeader ? (
              <FontAwesomeIcon icon={faBars} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;