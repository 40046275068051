
import React, { useState, useEffect } from 'react';
import UserHeader from './UserHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClockRotateLeft, faFire, faFolderOpen, faShareNodes, faUser,faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import ViewProfile from './ViewProfile';
import Shareprofile from './Shareprofile';
import Accesshistory from './Accesshistory';
import Delete from './Delete';
import Notification from './Notification';
import Changepassword from './Changepassword';
import { BASE_URL } from './config';
import { setUserEmail } from './store';
import { useNavigate } from 'react-router-dom';
import { Toaster , toast } from 'react-hot-toast';

export default function UserDashboard() {
    const count =  useSelector((state) => state.userNotificationCount);
    const user_email = useSelector(state => state.user_email);

    const [userProfiles, setUserProfiles] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        const fetchUserProfiles = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/get_user_profiles_data`);
                if (response.data.status === 'success') {
                    setUserProfiles(response.data.userProfiles);
                } else {
                    console.error('Failed to fetch user profiles:', response.data.error);
                }
            } catch (error) {
                console.error('Error fetching user profiles:', error.response.data.error);
            }
        };

        fetchUserProfiles();
    }, []);

    const matchingProfiles = userProfiles.filter(profile => profile.email === user_email);

    const [isOpenNavbar, setOpenNavbar] = useState(false);
    const handleOpenNavbar = () => {
        setOpenNavbar(!isOpenNavbar);
    }

    const [activeTab, setActiveTab] = useState('file');
    const [active, setActive] = useState('div1');

    const handleTabClick = (tab) => {
        setActiveTab(tab === activeTab ? null : tab);
        setOpenNavbar(!isOpenNavbar);
        if (tab === 'clock') {
            handleAnchorClick('div4');
        } else {
            switch (tab) {
                case 'share':
                    handleAnchorClick('div2');
                    break;
                case 'wallet':
                    handleAnchorClick('div3');
                    break;
                case 'fire':
                    handleAnchorClick('div5');
                    break;
                case 'bell':
                    handleAnchorClick('div6');
                    break;
                case 'user':
                    handleAnchorClick('div7');
                    break;
                default:
                    handleAnchorClick('div1');
            }
        }
    };

    const handleAnchorClick = (tab) => {
        setActive(tab);
    };

    const iconMap = {
        'file': (
            <FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon>
        ),
        'share': (
            <FontAwesomeIcon icon={faShareNodes}></FontAwesomeIcon>
        ),
        'clock': (
            <FontAwesomeIcon icon={faClockRotateLeft}></FontAwesomeIcon>
        ),
        'fire': (
            <FontAwesomeIcon icon={faFire}></FontAwesomeIcon>
        ),
        'bell': (
            <div className="relative">
                <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
                {count > 0 && (
                    <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                        {count}
                    </span>
                )}
            </div>
        ),
        'user': (
            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
        )
    };

    const TabButton = ({ label, tab }) => {
        return (
            <button
                className={`flex justify-center gap-3 w-[80%] mx-auto rounded-xl py-2.5 ${tab === activeTab ? 'bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131]' : ''}`}
                onClick={() => handleTabClick(tab)}
            >
                <p className='w-[10%]'>{iconMap[tab]}</p>
                <span className="w-[50%] text-start">{label}</span>
            </button>
        );
    };

   

    const Logout =()=> {
        dispatch(setUserEmail(''));
        toast.success('Logged Out Successfully...Redirecting to Login Page')
        setTimeout(()=>{
          //  window.location.href="/userLogin"
       navigate("/userLogin")
        },500)
        
      }

    return (
        <div className='font-poppins bg-cover min-h-screen bg-no-repeat text-white' style={{ backgroundImage: 'url("/assets/bg3.png")' }}>
            <UserHeader />
            <Toaster />
            <div className='p-5 md:flex'>
                <div className="hidden md:block w-[25%]">
                    <nav className='mt-[30%]'>
                        <div>
                            <TabButton label='View Profile' tab='file' />
                            <TabButton label='Share Profile' tab='share' />
                            <TabButton label='Access History ' tab='clock' />
                            <TabButton label='Delete Profile' tab='fire' />
                            <TabButton label='Notifications' tab='bell' />
                            <TabButton label='Change Password' tab='user' />
                        </div>
                    </nav>
                </div>
                <div className='block md:hidden'>
                    <button onClick={handleOpenNavbar}>
                        <svg className="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h14M1 6h14M1 11h7" />
                        </svg>
                    </button>
                    {isOpenNavbar && (
                        <nav className='w-[80%] z-50 absolute border rounded-3xl bg-[#05295A] text-white grid content-around' data-aos="fade-right">
                            <div className='py-5'>
                                <TabButton label='View Profile' tab='file' />
                                <TabButton label='Share Profile' tab='share' />
                                <TabButton label='Access History ' tab='clock' />
                                <TabButton label='Delete Profile' tab='fire' />
                                <TabButton label='Notifications' tab='bell' />
                                <TabButton label='Change Password' tab='user' />
                                <div className='flex justify-start items-center gap-3 w-[45%] mx-auto rounded-xl py-2.5 '>
                                    <FontAwesomeIcon icon={faArrowRightFromBracket}/>
                                    <button className='py-1 rounded-full  font-bold px-3 text-sm' onClick={()=>Logout()}>Log Out</button>
                                </div>
                            </div>
                        </nav>
                    )}
                </div>
                <div id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <ViewProfile />
                </div>
                <div id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <Shareprofile />
                </div>
                <div id='div4' style={{ display: active === 'div4' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <Accesshistory />
                </div>
                <div id='div5' style={{ display: active === 'div5' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <Delete />
                </div>
                <div id='div6' style={{ display: active === 'div6' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <Notification />
                </div>
                <div id='div7' style={{ display: active === 'div7' ? 'block' : 'none' }} className='md:w-[75%]'>
                    <Changepassword />
                </div>
            </div>
        </div>
    )
}