import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import DataSpinners from "./Spinner";

import { BASE_URL } from "./config";

export default function ViewProfile() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [userId, setUserId] = useState("");
  const [isOpenDetails, setOpenDetails] = useState(false);
  const [userProfiles, setUserProfiles] = useState([]);
  const [documentType, setDocumentType] = useState(""); // New state for document type
  const user_email = useSelector((state) => state.user_email);

  const [Nft_user, setNft_user] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Nft_user.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < Math.ceil(Nft_user.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const Nft = [
    { name: "Order Form", img: "../assets/Order 2.jpg" },
    { name: "Consent Form", img: "../assets/Consent 2.jpeg" },
    { name: "DNA Raw Data", img: "../assets/DNA 4.jpg" },
    { name: "Genetic Report", img: "../assets/Generic 1.jpeg" },
    { name: "Blood Report", img: "../assets/Blood report 1.jpg" },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const user_id = searchParams.get("id");
    setUserId(user_id);
  }, []);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        setSpinner(true);
        const response = await axios.post(
          `${BASE_URL}/api/get_user__data_by_email`,
          { email: user_email }
        );
        if (response.data.status === "success") {
          setUserProfiles(response.data.userProfile);
          setUserId(response.data.userProfile.id);
        } else {
          console.error("Failed to fetch user profiles:", response.data.error);
        }
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        console.error("Error fetching user profiles:", error.message);
      }
    };

    if (user_email) {
      fetchUserProfiles();
    }
  }, [user_email]);

  useEffect(() => {
    const getRecords = async () => {
      if (!userId || !documentType) return; // Ensure both userId and documentType are set
      const formData = {
        userId: userId,
        documentType: documentType,
      };
      try {
        const response = await axios.post(
          `${BASE_URL}/api/get_reports`,
          formData
        );
        setNft_user(response.data.data);
      } catch (error) {
        console.error("Error fetching records:", error.message);
        toast.error("Failed to fetch records.");
      }
    };
    getRecords();
  }, [userId, documentType]); // Depend on both userId and documentType

  const handleOpenDetails = () => {
    setOpenDetails(!isOpenDetails);
  };

  const [isopen, setIsOpen] = useState(false);

  const handleNftClick = (type) => {
    const selectedNft = Nft.find((nft) => nft.name === type);
    if (selectedNft) {
      setDocumentType(type);
      setNftImageUrl(selectedNft.img); // Set the image URL for Nft_user
      setIsOpen(true);
    }
  };

  // State to store the image URL for Nft_user
  const [nftImageUrl, setNftImageUrl] = useState("");

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };


  return (
    <div>
      <div className="flex min-h-screen font-inter">
        <div className="lg:w-[90%] mx-auto relative">
          <div className="h-screen md:h-full overflow-hidden overflow-y-auto md:overflow-hidden">
            <div className="bg-white/25 rounded-lg py-5 cursor-pointer">
              <div className="w-full">
                <div className="flex flex-wrap justify-center items-center gap-5">
                  {Nft.map((data, index) => (
                    <div
                      key={index}
                      className="bg-white h-[13rem] p-4 rounded-lg flex flex-col justify-center items-center md:w-[24%] sm:w-[30%] w-[12rem] cursor-pointer"
                      onClick={() => handleNftClick(data.name)}
                    >
                      <img src={data.img} alt="Nft" className="h-[10rem]" />
                      <p className="text-sm text-[#000000A6] font-medium">
                        {data.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {isopen && (
            <div className="w-full mx-auto flex flex-wrap absolute top-0 left-0 z-10 bg-[#05295A] p-7 min-h-screen justify-center gap-5">
              <button
                className="absolute top-0 left-0"
                onClick={() => setIsOpen(false)}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <div className="w-[90%] mx-auto z-10 ">
                <div className="h-screen md:h-full overflow-hidden overflow-y-auto md:overflow-hidden">
                  <div
                    className={`${isDarkMode ? "bg-white/25" : "bg-[#3ab7ff28]"
                      } rounded-lg p-5 cursor-pointer`}
                  >
                    <div className="w-full">
                      <div className="flex flex-wrap justify-center items-center gap-5">
                        {currentItems.length === 0 ? (
                          <p className="font-bold">No data found.</p>
                        ) : (
                          currentItems.map((data, index) => (
                            <a
                              href={data.document_url.replace(
                                "../client/public/",
                                "/"
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              key={index}
                              className="bg-white p-3 rounded-lg flex flex-col justify-center items-center md:w-[25%]" //md:w-[24%] sm:w-[30%] w-[12rem]
                            >
                              <div className="flex relative justify-center items-center pr-5">
                                <div className="">
                                  <p className="text-sm w-5 text-[#000000A6] font-medium rotate-90"></p>
                                </div>
                                <img
                                src={nftImageUrl}
                                alt="Nft"
                                  className="h-[10rem]"
                                />
                              </div>
                              <div className="w-full overflow-hidden overflow-x-auto mt-2">
                                <p className="text-sm text-[#000000A6] text-center">
                                  {truncateString(data.reportNumber, 8)} {data.created_at.split('T')[0]}
                                </p>      
                              </div>
                            </a>
                          ))
                        )}
                      </div>
                      <div className="mt-4 flex justify-between w-full px-4">
                        <button
                          onClick={prevPage}
                          disabled={currentPage === 1}
                          className="bg-gray-300 text-gray-700 py-2 px-4 rounded disabled:opacity-50"
                        >
                          Prev
                        </button>
                        <button
                          onClick={nextPage}
                          disabled={currentPage === Math.ceil(Nft_user.length / itemsPerPage)}
                          className="bg-gray-300 text-gray-700 py-2 px-4 rounded disabled:opacity-50"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {spinner ? <DataSpinners /> : ""}

      <Toaster />
    </div>
  );
}