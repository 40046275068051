import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import '../App.css';

const ViewProfile = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [userId, setUserId] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const user_id = searchParams.get('id');
        setUserId(user_id);
    }, []);

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const Nft = [
        { name: "Order Form", img: "../assets/Order 2.jpg" },
        { name: "Consent Form", img: "../assets/Consent 2.jpeg" },
        { name: "DNA Raw Data", img: "../assets/DNA 4.jpg" },
        { name: "Genetic Report", img: "../assets/Generic 1.jpeg" },
        { name: "Blood Report", img: "../assets/Blood report 1.jpg" },
    ];

    return (
        <div className={`relative ${isDarkMode ? 'dark' : 'light'}`}>
            <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
            <div className={`flex min-h-screen font-inter pt-5 ${isDarkMode ? 'bg-[#05295A]' : 'bg-white'}`}>
                <Sidebar isDarkMode={isDarkMode} />
                <div className='lg:w-[70%] lg:mx-auto mx-10  z-10 relative'>
                    <div className='h-screen md:h-full overflow-hidden overflow-y-auto md:overflow-hidden '>
                        <div className={` ${isDarkMode ? 'bg-white/25' : 'bg-[#3ab7ff28]' }   rounded-lg p-5 cursor-pointer`} >
                            <div className='w-full '>
                                <div className=' flex flex-wrap justify-center items-center gap-5 '>
                                    {Nft.map((data, index) => (
                                        <div key={index} className='bg-white h-[13rem] p-2 rounded-lg flex flex-col justify-center items-center md:w-[24%] sm:w-[30%] w-[12rem] ' onClick={() => window.location.href = `/Admin/ViewReports?id=${userId}&document_type=${data.name}&imgUrl=${data.img}`}>
                                            <img src={data.img} alt='Nft' className='sm:w-9/12 w-8/12 h-[10rem]' />
                                            <p className='text-sm text-[#000000A6]'>{data.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewProfile;